<template>
  <div class="container py-5">
    <p class="fw-bold">第十七屆美沃斯國際醫學美容大會</p>
    <p>時間：2024/05/10-12</p>
    <p>地點：杭州國際博覽中心</p>
    <p>展位：B133/A134</p>

    <p>
    銘伊恆美集團始終致力於推動業界的技術創新與服務升級。 我們深知，美沃斯大會是醫美界最具影響力的學術盛會之一，每年都有大量的專家學者和業界領袖齊聚一堂，共襄盛舉。
    <br> <br>
    大會期間，銘伊恆美集團將展示我們最新的技術成果和產品，並與與會者分享我們在醫美領域的經驗和見解。 同時，我們也期待與各位專家學者和業界領袖進行深入的交流與探討，共同推動醫美產業的進步與發展。
    <br> <br>
    本次大會匯集了350餘位專家學者與產業領袖，吸引了400多家廠商參展。 會議內容精彩紛呈，涵蓋12大板塊，包括29台手術、微整、私人示教展示+2場解剖實操。 此外，還有超60個營運、管理、諮詢案例的深度剖析。 我們已做好充分準備，期待與您共同探討產業發展趨勢。
    <br> <br>
    5月10-12日，我們在杭州國際博覽中心，期待您如期赴約。
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_39.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
